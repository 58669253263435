.c-theme-tiles {
  border-top: line-width("xx-small") solid shade("lightest-grey");
  padding-top: spacing-unit("xx-small");

  &--stand-alone {
    border-top: line-width("none");
  }
}

.c-theme-tiles__tile {
  box-shadow: spacing-unit("xx-small") spacing-unit("xx-small")
    spacing-unit("xx-small") spacing-unit("none") $shadow;
  height: 100px;
  width: 100px;

  &--stand-alone {
    height: 200px;
    width: 200px;
  }
  
  &--indicator {
    height: 165px;
    width: 165px;
  }
}

.c-theme-tiles__image {
  &--stand-alone {
    height: 200px;
    width: 200px;
  }
  
  &--indicator {
    height: 165px;
    width: 165px;
  }
}
