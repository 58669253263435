.c-indicator-metadata {
  font-size: font-size("small");
}

.c-indicator-metadata__key {
  display: inline;
  font-weight: 700;

  &::after {
    content: ": ";
  }
}

.c-indicator-metadata__value {
  display: inline;
}

.c-indicator-metadata__values {
  display: list-item;
  list-style-type: disc;
  margin-left: spacing-unit(small);
}
