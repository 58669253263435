@use "sass:color";

@import "functions";

// Spacing units

// These spacing units were generated by Utopia's fluid space calculator.
// See https://utopia.fyi/ for more information and
// https://utopia.fyi/space/calculator?c=320,16,1.2,1240,20,1.25,5,2,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l&g=s,l,xl,12
// to reproduce them.

$spacing-units: (
  "none": 0,
  "xxx-small": clamp(0.25rem, calc(0.23rem + 0.11vw), 0.31rem),
  "xx-small": clamp(0.5rem, calc(0.46rem + 0.22vw), 0.63rem),
  "x-small": clamp(0.75rem, calc(0.68rem + 0.33vw), 0.94rem),
  "small": clamp(1rem, calc(0.91rem + 0.43vw), 1.25rem),
  "medium": clamp(1.5rem, calc(1.37rem + 0.65vw), 1.88rem),
  "large": clamp(2rem, calc(1.83rem + 0.87vw), 2.5rem),
  "x-large": clamp(3rem, calc(2.74rem + 1.3vw), 3.75rem),
  "xx-large": clamp(4rem, calc(3.65rem + 1.74vw), 5rem),
  "xxx-large": clamp(6rem, calc(5.48rem + 2.61vw), 7.5rem),
);

// Font sizes

// These spacing units were generated by Utopia's fluid type scale calculator.
// See https://utopia.fyi/ for more information and
// https://utopia.fyi/type/calculator?c=320,16,1.125,1240,20,1.125,4,2,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l&g=s,l,xl,12
// to reproduce them.

$font-sizes: (
  "x-small": clamp(0.79rem, calc(0.72rem + 0.34vw), 0.99rem),
  "small": clamp(0.89rem, calc(0.81rem + 0.39vw), 1.11rem),
  "medium": clamp(1rem, calc(0.91rem + 0.43vw), 1.25rem),
  "large": clamp(1.13rem, calc(1.03rem + 0.49vw), 1.41rem),
  "x-large": clamp(1.27rem, calc(1.16rem + 0.55vw), 1.58rem),
  "xx-large": clamp(1.42rem, calc(1.3rem + 0.62vw), 1.78rem),
  "xxx-large": clamp(1.6rem, calc(1.46rem + 0.7vw), 2rem),
);

// Line heights

$line-heights: (
  "medium": 1.5,
  "small": 1.4,
  "x-small": 1.3,
  "xx-small": 1.2,
  "xxx-small": 1.1,
);

// Line widths

$line-widths: (
  "none": 0,
  "xxx-small": 0.0625rem,
  "xx-small": 0.125rem,
  "x-small": 0.1875rem,
  "small": 0.25rem,
  "medium": 0.3125rem,
  "large": 0.375rem,
  "x-large": 0.4375rem,
  "xx-large": 0.5rem,
  "xxx-large": 0.5625rem,
);

// Transition times

$transition-time: 250ms;

// Breakpoints

$mobile: 450px;
$tablet: 850px;

// Colours

// Defra greens

$defra-green: #008938;
$darker-defra-green: color.adjust($defra-green, $lightness: -5%);
$darkest-defra-green: color.adjust($defra-green, $lightness: -15%);

// Shades

$shades: (
  "black": hsl(0, 0%, 2%),
  "darkest-grey": hsl(0, 0%, 14%),
  "darker-grey": hsl(0, 0%, 26%),
  "dark-grey": hsl(0, 0%, 38%),
  "grey": hsl(0, 0%, 50%),
  "light-grey": hsl(0, 0%, 62%),
  "lighter-grey": hsl(0, 0%, 74%),
  "lightest-grey": hsl(0, 0%, 86%),
  "white": hsl(0, 0%, 98%),
);

// Other colours

$shadow: rgba(shade("black"), 30%);
$blue: #1d70b8;
$dark-blue: #005ea5;
$dark-pink: #dd1367;
$purple: #580829;
$yellow: #fd0;
