*, :before, :after {
  box-sizing: border-box;
}

body, h1, h2, h3, h4, p, figure, blockquote, dl, dd {
  margin: 0;
}

ul, ol {
  margin-top: 0;
  margin-bottom: 0;
}

ul[role="list"], ol[role="list"] {
  list-style: none;
}

html:focus-within {
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
  text-rendering: optimizespeed;
}

a:not([class]) {
  -webkit-text-decoration-skip-ink: auto;
  text-decoration-skip-ink: auto;
}

img, picture, svg {
  height: auto;
  max-width: 100%;
  margin: auto;
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *, :before, :after {
    scroll-behavior: auto !important;
    transition-duration: .01ms !important;
    -webkit-animation-duration: .01ms !important;
    animation-duration: .01ms !important;
    -webkit-animation-iteration-count: 1 !important;
    animation-iteration-count: 1 !important;
  }
}

body {
  color: #050505;
  background: #fafafa;
  font-family: Arial, Helvetica, sans-serif;
  font-size: max(1rem, min(.91rem + .43vw, 1.25rem));
  line-height: 1.5;
}

p, li, h1, h2, h3, h4 {
  word-break: break-word;
  margin-top: 0;
}

h1, h2, h3, h4 {
  font-weight: 500;
}

h1 {
  font-size: max(1.6rem, min(1.46rem + .7vw, 2rem));
  line-height: 1.1;
}

h2 {
  font-size: max(1.42rem, min(1.3rem + .62vw, 1.78rem));
  line-height: 1.2;
}

h3 {
  font-size: max(1.27rem, min(1.16rem + .55vw, 1.58rem));
  line-height: 1.3;
}

h4 {
  font-size: max(1.13rem, min(1.03rem + .49vw, 1.41rem));
  line-height: 1.4;
}

.l-column {
  flex-direction: column;
  display: flex;
}

.l-column--no-space {
  gap: 0;
}

.l-column--xxx-small-space {
  gap: max(.25rem, min(.23rem + .11vw, .31rem));
}

.l-column--xx-small-space {
  gap: max(.5rem, min(.46rem + .22vw, .63rem));
}

.l-column--x-small-space {
  gap: max(.75rem, min(.68rem + .33vw, .94rem));
}

.l-column--small-space {
  gap: max(1rem, min(.91rem + .43vw, 1.25rem));
}

.l-column--medium-space {
  gap: max(1.5rem, min(1.37rem + .65vw, 1.88rem));
}

.l-column--large-space {
  gap: max(2rem, min(1.83rem + .87vw, 2.5rem));
}

.l-column--x-large-space {
  gap: max(3rem, min(2.74rem + 1.3vw, 3.75rem));
}

.l-column--xx-large-space {
  gap: max(4rem, min(3.65rem + 1.74vw, 5rem));
}

.l-column--xxx-large-space {
  gap: max(6rem, min(5.48rem + 2.61vw, 7.5rem));
}

.l-column--centred {
  justify-content: center;
  align-items: center;
  justify-items: center;
}

.l-column--vertically-centred {
  align-items: center;
}

.l-column--horizontally-centred {
  justify-content: center;
}

.l-column--wrap {
  flex-wrap: wrap;
}

.l-column--space-between {
  justify-content: space-between;
}

.l-column--main {
  width: min(95vw, 1170px);
  margin: 0 auto;
}

.l-column--vertical-bottom {
  justify-content: end;
}

.l-row {
  flex-direction: row;
  display: flex;
}

.l-row--no-space {
  gap: 0;
}

.l-row--xxx-small-space {
  gap: max(.25rem, min(.23rem + .11vw, .31rem));
}

.l-row--xx-small-space {
  gap: max(.5rem, min(.46rem + .22vw, .63rem));
}

.l-row--x-small-space {
  gap: max(.75rem, min(.68rem + .33vw, .94rem));
}

.l-row--small-space {
  gap: max(1rem, min(.91rem + .43vw, 1.25rem));
}

.l-row--medium-space {
  gap: max(1.5rem, min(1.37rem + .65vw, 1.88rem));
}

.l-row--large-space {
  gap: max(2rem, min(1.83rem + .87vw, 2.5rem));
}

.l-row--x-large-space {
  gap: max(3rem, min(2.74rem + 1.3vw, 3.75rem));
}

.l-row--xx-large-space {
  gap: max(4rem, min(3.65rem + 1.74vw, 5rem));
}

.l-row--xxx-large-space {
  gap: max(6rem, min(5.48rem + 2.61vw, 7.5rem));
}

.l-row--centred {
  justify-content: center;
  align-items: center;
  justify-items: center;
}

.l-row--vertically-centred {
  align-items: center;
}

.l-row--horizontally-centred {
  justify-content: center;
}

.l-row--wrap {
  flex-wrap: wrap;
}

.l-row--space-between {
  justify-content: space-between;
}

.l-grid {
  display: grid;
}

.l-grid--no-space {
  gap: 0;
}

.l-grid--xxx-small-space {
  gap: max(.25rem, min(.23rem + .11vw, .31rem));
}

.l-grid--xx-small-space {
  gap: max(.5rem, min(.46rem + .22vw, .63rem));
}

.l-grid--x-small-space {
  gap: max(.75rem, min(.68rem + .33vw, .94rem));
}

.l-grid--small-space {
  gap: max(1rem, min(.91rem + .43vw, 1.25rem));
}

.l-grid--medium-space {
  gap: max(1.5rem, min(1.37rem + .65vw, 1.88rem));
}

.l-grid--large-space {
  gap: max(2rem, min(1.83rem + .87vw, 2.5rem));
}

.l-grid--x-large-space {
  gap: max(3rem, min(2.74rem + 1.3vw, 3.75rem));
}

.l-grid--xx-large-space {
  gap: max(4rem, min(3.65rem + 1.74vw, 5rem));
}

.l-grid--xxx-large-space {
  gap: max(6rem, min(5.48rem + 2.61vw, 7.5rem));
}

.l-grid--centred {
  justify-content: center;
  align-items: center;
  justify-items: center;
}

.l-grid--vertically-centred {
  align-items: center;
}

.l-grid--horizontally-centred {
  justify-content: center;
}

.l-equal-width-columns {
  grid-template-columns: repeat(8, 1fr);
  display: grid;
}

.l-equal-width-columns--no-space {
  gap: 0;
}

.l-equal-width-columns--xxx-small-space {
  gap: max(.25rem, min(.23rem + .11vw, .31rem));
}

.l-equal-width-columns--xx-small-space {
  gap: max(.5rem, min(.46rem + .22vw, .63rem));
}

.l-equal-width-columns--x-small-space {
  gap: max(.75rem, min(.68rem + .33vw, .94rem));
}

.l-equal-width-columns--small-space {
  gap: max(1rem, min(.91rem + .43vw, 1.25rem));
}

.l-equal-width-columns--medium-space {
  gap: max(1.5rem, min(1.37rem + .65vw, 1.88rem));
}

.l-equal-width-columns--large-space {
  gap: max(2rem, min(1.83rem + .87vw, 2.5rem));
}

.l-equal-width-columns--x-large-space {
  gap: max(3rem, min(2.74rem + 1.3vw, 3.75rem));
}

.l-equal-width-columns--xx-large-space {
  gap: max(4rem, min(3.65rem + 1.74vw, 5rem));
}

.l-equal-width-columns--xxx-large-space {
  gap: max(6rem, min(5.48rem + 2.61vw, 7.5rem));
}

.l-equal-width-columns--centred {
  justify-content: center;
  align-items: center;
  justify-items: center;
}

.l-equal-width-columns--vertically-centred {
  align-items: center;
}

.l-equal-width-columns--horizontally-centred {
  justify-content: center;
}

.l-equal-width-columns-download {
  grid-template-columns: repeat(4, 1fr);
  display: grid;
}

.l-equal-width-columns-download--no-space {
  gap: 0;
}

.l-equal-width-columns-download--xxx-small-space {
  gap: max(.25rem, min(.23rem + .11vw, .31rem));
}

.l-equal-width-columns-download--xx-small-space {
  gap: max(.5rem, min(.46rem + .22vw, .63rem));
}

.l-equal-width-columns-download--x-small-space {
  gap: max(.75rem, min(.68rem + .33vw, .94rem));
}

.l-equal-width-columns-download--small-space {
  gap: max(1rem, min(.91rem + .43vw, 1.25rem));
}

.l-equal-width-columns-download--medium-space {
  gap: max(1.5rem, min(1.37rem + .65vw, 1.88rem));
}

.l-equal-width-columns-download--large-space {
  gap: max(2rem, min(1.83rem + .87vw, 2.5rem));
}

.l-equal-width-columns-download--x-large-space {
  gap: max(3rem, min(2.74rem + 1.3vw, 3.75rem));
}

.l-equal-width-columns-download--xx-large-space {
  gap: max(4rem, min(3.65rem + 1.74vw, 5rem));
}

.l-equal-width-columns-download--xxx-large-space {
  gap: max(6rem, min(5.48rem + 2.61vw, 7.5rem));
}

.l-equal-width-columns-download--centred {
  justify-content: center;
  align-items: center;
  justify-items: center;
}

.l-equal-width-columns-download--vertically-centred {
  align-items: center;
}

.l-equal-width-columns-download--horizontally-centred {
  justify-content: center;
}

.l-equal-width-columns-assessment {
  grid-template-columns: repeat(6, 1fr);
  display: grid;
}

.l-equal-width-columns-assessment--no-space {
  gap: 0;
}

.l-equal-width-columns-assessment--xxx-small-space {
  gap: max(.25rem, min(.23rem + .11vw, .31rem));
}

.l-equal-width-columns-assessment--xx-small-space {
  gap: max(.5rem, min(.46rem + .22vw, .63rem));
}

.l-equal-width-columns-assessment--x-small-space {
  gap: max(.75rem, min(.68rem + .33vw, .94rem));
}

.l-equal-width-columns-assessment--small-space {
  gap: max(1rem, min(.91rem + .43vw, 1.25rem));
}

.l-equal-width-columns-assessment--medium-space {
  gap: max(1.5rem, min(1.37rem + .65vw, 1.88rem));
}

.l-equal-width-columns-assessment--large-space {
  gap: max(2rem, min(1.83rem + .87vw, 2.5rem));
}

.l-equal-width-columns-assessment--x-large-space {
  gap: max(3rem, min(2.74rem + 1.3vw, 3.75rem));
}

.l-equal-width-columns-assessment--xx-large-space {
  gap: max(4rem, min(3.65rem + 1.74vw, 5rem));
}

.l-equal-width-columns-assessment--xxx-large-space {
  gap: max(6rem, min(5.48rem + 2.61vw, 7.5rem));
}

.l-equal-width-columns-assessment--centred {
  justify-content: center;
  align-items: center;
  justify-items: center;
}

.l-equal-width-columns-assessment--vertically-centred {
  align-items: center;
}

.l-equal-width-columns-assessment--horizontally-centred {
  justify-content: center;
}

.l-cards {
  gap: max(1rem, min(.91rem + .43vw, 1.25rem));
  display: grid;
}

.l-cards--no-space {
  gap: 0;
}

.l-cards--xxx-small-space {
  gap: max(.25rem, min(.23rem + .11vw, .31rem));
}

.l-cards--xx-small-space {
  gap: max(.5rem, min(.46rem + .22vw, .63rem));
}

.l-cards--x-small-space {
  gap: max(.75rem, min(.68rem + .33vw, .94rem));
}

.l-cards--small-space {
  gap: max(1rem, min(.91rem + .43vw, 1.25rem));
}

.l-cards--medium-space {
  gap: max(1.5rem, min(1.37rem + .65vw, 1.88rem));
}

.l-cards--large-space {
  gap: max(2rem, min(1.83rem + .87vw, 2.5rem));
}

.l-cards--x-large-space {
  gap: max(3rem, min(2.74rem + 1.3vw, 3.75rem));
}

.l-cards--xx-large-space {
  gap: max(4rem, min(3.65rem + 1.74vw, 5rem));
}

.l-cards--xxx-large-space {
  gap: max(6rem, min(5.48rem + 2.61vw, 7.5rem));
}

.l-cards--centred {
  justify-content: center;
  align-items: center;
  justify-items: center;
}

.l-cards--vertically-centred {
  align-items: center;
}

.l-cards--horizontally-centred {
  justify-content: center;
}

@media screen and (min-width: 850px) {
  .l-cards {
    grid-template-columns: repeat(3, 1fr);
  }
}

.l-header {
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas: "phase phase"
                       "feedback feedback"
                       "logo logo"
                       "nav search";
  gap: max(.75rem, min(.68rem + .33vw, .94rem)) 0;
  display: grid;
}

.l-header--no-space {
  gap: 0;
}

.l-header--xxx-small-space {
  gap: max(.25rem, min(.23rem + .11vw, .31rem));
}

.l-header--xx-small-space {
  gap: max(.5rem, min(.46rem + .22vw, .63rem));
}

.l-header--x-small-space {
  gap: max(.75rem, min(.68rem + .33vw, .94rem));
}

.l-header--small-space {
  gap: max(1rem, min(.91rem + .43vw, 1.25rem));
}

.l-header--medium-space {
  gap: max(1.5rem, min(1.37rem + .65vw, 1.88rem));
}

.l-header--large-space {
  gap: max(2rem, min(1.83rem + .87vw, 2.5rem));
}

.l-header--x-large-space {
  gap: max(3rem, min(2.74rem + 1.3vw, 3.75rem));
}

.l-header--xx-large-space {
  gap: max(4rem, min(3.65rem + 1.74vw, 5rem));
}

.l-header--xxx-large-space {
  gap: max(6rem, min(5.48rem + 2.61vw, 7.5rem));
}

.l-header--centred {
  justify-content: center;
  align-items: center;
  justify-items: center;
}

.l-header--vertically-centred {
  align-items: center;
}

.l-header--horizontally-centred {
  justify-content: center;
}

@media screen and (min-width: 850px) {
  .l-header {
    grid-template-areas: "phase feedback"
                         "logo search"
                         "nav nav";
    gap: max(.75rem, min(.68rem + .33vw, .94rem)) max(1rem, min(.91rem + .43vw, 1.25rem));
    margin-top: max(.5rem, min(.46rem + .22vw, .63rem));
  }
}

.l-header__phase {
  grid-area: phase;
}

.l-header__feedback {
  grid-area: feedback;
}

@media screen and (min-width: 850px) {
  .l-header__feedback {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    align-self: flex-end;
    justify-self: flex-end;
  }
}

.l-header__logo {
  grid-area: logo;
}

.l-header__nav {
  grid-area: nav;
}

.l-header__search {
  grid-area: search;
}

.l-theme-tiles {
  grid-template-columns: repeat(auto-fill, 100px);
  justify-content: center;
  gap: max(1rem, min(.91rem + .43vw, 1.25rem));
  margin-top: max(1rem, min(.91rem + .43vw, 1.25rem));
  margin-bottom: max(1rem, min(.91rem + .43vw, 1.25rem));
  display: grid;
}

.l-theme-tiles--no-space {
  gap: 0;
}

.l-theme-tiles--xxx-small-space {
  gap: max(.25rem, min(.23rem + .11vw, .31rem));
}

.l-theme-tiles--xx-small-space {
  gap: max(.5rem, min(.46rem + .22vw, .63rem));
}

.l-theme-tiles--x-small-space {
  gap: max(.75rem, min(.68rem + .33vw, .94rem));
}

.l-theme-tiles--small-space {
  gap: max(1rem, min(.91rem + .43vw, 1.25rem));
}

.l-theme-tiles--medium-space {
  gap: max(1.5rem, min(1.37rem + .65vw, 1.88rem));
}

.l-theme-tiles--large-space {
  gap: max(2rem, min(1.83rem + .87vw, 2.5rem));
}

.l-theme-tiles--x-large-space {
  gap: max(3rem, min(2.74rem + 1.3vw, 3.75rem));
}

.l-theme-tiles--xx-large-space {
  gap: max(4rem, min(3.65rem + 1.74vw, 5rem));
}

.l-theme-tiles--xxx-large-space {
  gap: max(6rem, min(5.48rem + 2.61vw, 7.5rem));
}

.l-theme-tiles--centred {
  justify-content: center;
  align-items: center;
  justify-items: center;
}

.l-theme-tiles--vertically-centred {
  align-items: center;
}

.l-theme-tiles--horizontally-centred {
  justify-content: center;
}

.l-theme-tiles--stand-alone {
  grid-template-columns: repeat(auto-fill, 200px);
}

.c-breadcrumbs__items {
  margin: 0;
  padding: 0;
  list-style: none;
}

.c-breadcrumbs__item:not(:first-child):before {
  content: ">";
  margin-right: max(.5rem, min(.46rem + .22vw, .63rem));
}

.c-breadcrumbs__link {
  color: #050505;
}

.c-button {
  cursor: pointer;
  min-height: 44px;
  min-width: 44px;
  background: none;
  background: inherit;
  color: inherit;
  color: #fafafa;
  background: #00702e;
  border: 0;
  border-bottom: .25rem solid #003d19;
  justify-content: center;
  align-items: center;
  padding: max(.75rem, min(.68rem + .33vw, .94rem));
  font-family: inherit;
  text-decoration: none;
  display: inline-flex;
}

@media screen and (-ms-high-contrast: active) {
  .c-button {
    border: .25rem solid;
  }
}

.c-button:active, .c-button:focus {
  color: #050505;
  background: #fd0;
  border-bottom: .375rem solid #050505;
  box-shadow: 0 -.25rem #fd0;
}

.c-button--feedback {
  background: #dd1367;
  border-bottom: .25rem solid #580829;
}

.c-button--search {
  border-bottom: 0;
  padding: 0;
}

.c-button--search:focus, .c-button--search:active {
  border: 0;
}

.c-button--fit-content {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.c-button--previous:before {
  content: "❮";
  margin-right: max(.5rem, min(.46rem + .22vw, .63rem));
}

.c-button--next:after {
  content: "❯";
  margin-left: max(.5rem, min(.46rem + .22vw, .63rem));
}

.closebtn {
  color: #fff;
  float: right;
  cursor: pointer;
  margin-left: 15px;
  font-size: 22px;
  font-weight: bold;
  line-height: 20px;
  transition: all .3s;
}

.closebtn:hover {
  color: #000;
}

.c-colour-bar {
  height: .5625rem;
  background: #008938;
  margin: 0;
}

.c-footer {
  background: #dbdbdb;
  border-top: .125rem solid #9e9e9e;
  padding-top: max(.5rem, min(.46rem + .22vw, .63rem));
}

.c-footer__items {
  margin: 0;
  padding: 0;
  list-style: none;
}

.c-footer__link {
  color: #050505;
}

.c-frontpage-card {
  border-top: .125rem solid #dbdbdb;
  padding-top: max(.5rem, min(.46rem + .22vw, .63rem));
}

.c-frontpage-card__title {
  margin-bottom: 0;
}

.c-frontpage-card__content {
  flex-grow: 1;
}

.c-frontpage-card__content--items {
  margin: 0;
  padding: 0;
  list-style: none;
}

.c-frontpage-card__link {
  color: #1d70b8;
}

.c-indicator-metadata {
  font-size: max(.89rem, min(.81rem + .39vw, 1.11rem));
}

.c-indicator-metadata__key {
  font-weight: 700;
  display: inline;
}

.c-indicator-metadata__key:after {
  content: ": ";
}

.c-indicator-metadata__value {
  display: inline;
}

.c-indicator-metadata__values {
  margin-left: max(1rem, min(.91rem + .43vw, 1.25rem));
  list-style-type: disc;
  display: list-item;
}

.c-indicator-component-tab {
  cursor: pointer;
  min-height: 44px;
  min-width: 44px;
  background: none;
  border: 0;
  padding: 0;
  font-family: inherit;
}

@media screen and (-ms-high-contrast: active) {
  .c-indicator-component-tab {
    border: .25rem solid;
  }
}

.c-indicator-component-tab__text {
  pointer-events: none;
  text-align: left;
}

.c-indicator-component-tab__radio-button {
  min-height: 44px;
  min-width: 44px;
}

.c-indicator-component-tabpanel--closed {
  display: none;
}

.c-indicator-component-visualisation-tab {
  cursor: pointer;
  min-height: 44px;
  min-width: 44px;
  background: none;
  background: inherit;
  color: inherit;
  background: #fafafa;
  border: 0;
  border-left: .0625rem solid #9e9e9e;
  border-right: .0625rem solid #9e9e9e;
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
  justify-content: center;
  align-items: center;
  padding: max(.25rem, min(.23rem + .11vw, .31rem)) max(.75rem, min(.68rem + .33vw, .94rem));
  font-family: inherit;
  display: inline-flex;
  box-shadow: 0 -6px #008938;
}

@media screen and (-ms-high-contrast: active) {
  .c-indicator-component-visualisation-tab {
    border: .25rem solid;
  }
}

.c-indicator-component-visualisation-tab--closed {
  box-shadow: none;
  background: #dbdbdb;
  border: .0625rem solid #9e9e9e;
  border-bottom-width: .125rem;
}

.c-indicator-component-visualisation-tab:focus, .c-indicator-component-visualisation-tab:hover {
  background: #fafafa;
}

.c-indicator-component-visualisation-tab__text {
  pointer-events: none;
  border: .125rem solid rgba(0, 0, 0, 0);
  border-radius: .25rem;
  padding: max(.25rem, min(.23rem + .11vw, .31rem));
}

.c-indicator-component-visualisation-tab__text:focus, .c-indicator-component-visualisation-tab__text:hover {
  background: #fafafa;
  border: .125rem solid #008938;
}

.c-indicator-component-visualisation-tabpanel--closed {
  display: none;
}

.c-indicator-component-visualisation-tabpanels {
  min-height: 620px;
  border: .0625rem solid #9e9e9e;
  padding: max(1rem, min(.91rem + .43vw, 1.25rem));
}

.c-indicator-component-metadata {
  font-size: max(.89rem, min(.81rem + .39vw, 1.11rem));
}

.c-indicator-component-metadata__key {
  font-weight: 700;
}

.c-indicator-component-metadata__key:after {
  content: ":";
}

.c-indicator-component-metadata__value {
  display: inline;
}

.c-indicator-component-metadata__values {
  margin-left: max(1rem, min(.91rem + .43vw, 1.25rem));
  list-style-type: disc;
  display: list-item;
}

.c-logo__image {
  height: 100%;
}

.c-navigation {
  color: #fafafa;
  z-index: 998;
  background: #242424;
  position: relative;
}

@media screen and (min-width: 850px) {
  .c-navigation {
    color: #050505;
    background: #fafafa;
  }
}

.c-navigation__toggle {
  cursor: pointer;
  min-height: 44px;
  min-width: 44px;
  background: none;
  background: inherit;
  color: inherit;
  height: 100%;
  width: 100%;
  border: 0;
  border-bottom: .375rem solid #242424;
  justify-content: center;
  align-items: center;
  padding: max(.25rem, min(.23rem + .11vw, .31rem)) max(.75rem, min(.68rem + .33vw, .94rem));
  font-family: inherit;
  display: inline-flex;
}

@media screen and (-ms-high-contrast: active) {
  .c-navigation__toggle {
    border: .25rem solid;
  }
}

.c-navigation__toggle--clicked {
  background: #424242;
  border-bottom: .375rem solid #424242;
}

@media screen and (min-width: 850px) {
  .c-navigation__toggle {
    display: none;
  }
}

.c-navigation__items {
  color: inherit;
  width: 200%;
  background: #424242;
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
}

.c-navigation__items--closed {
  display: none;
}

@media screen and (min-width: 850px) {
  .c-navigation__items {
    color: #050505;
    max-width: 100%;
    position: unset;
    background: #fafafa;
    flex-direction: row;
    display: flex;
  }
}

.c-navigation__item:focus, .c-navigation__item:active {
  color: #050505;
  background: #fd0;
}

@media screen and (min-width: 850px) {
  .c-navigation__item {
    border-bottom: .25rem solid rgba(0, 0, 0, 0);
  }

  .c-navigation__item:hover {
    border-bottom: .25rem solid #050505;
  }
}

.c-navigation__link {
  cursor: pointer;
  min-height: 44px;
  min-width: 44px;
  background: none;
  background: inherit;
  color: inherit;
  background: inherit;
  color: inherit;
  width: 100%;
  border: 0;
  justify-content: flex-start;
  align-items: center;
  padding: max(.25rem, min(.23rem + .11vw, .31rem)) max(.75rem, min(.68rem + .33vw, .94rem));
  font-family: inherit;
  text-decoration: none;
  display: inline-flex;
}

@media screen and (-ms-high-contrast: active) {
  .c-navigation__link {
    border: .25rem solid;
  }
}

.c-navigation__item .c-navigation__toggle {
  justify-content: space-between;
}

.c-navigation__item .c-navigation__toggle:after {
  content: "▼";
  transition: all .25s ease-in;
}

.c-navigation__item .c-navigation__toggle--clicked:after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.c-navigation__item .c-navigation__toggle:focus, .c-navigation__item .c-navigation__toggle:active {
  box-shadow: none;
  border-bottom: 0;
}

.c-navigation__item .c-navigation__items {
  color: #fafafa;
  position: unset;
  width: 100%;
  background: #616161;
}

@media screen and (min-width: 850px) {
  .c-navigation__item .c-navigation__items {
    color: #050505;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background: #fafafa;
    border: .1875rem solid #9e9e9e;
    flex-direction: column;
    margin-top: .25rem;
    position: absolute;
    box-shadow: 0 max(.75rem, min(.68rem + .33vw, .94rem)) max(.75rem, min(.68rem + .33vw, .94rem)) rgba(5, 5, 5, .3);
  }

  .c-navigation__item .c-navigation__items--closed {
    display: none;
  }
}

.c-navigation__item .c-navigation__item {
  padding-left: max(1rem, min(.91rem + .43vw, 1.25rem));
}

@media screen and (min-width: 850px) {
  .c-navigation__item .c-navigation__item:hover {
    background: #dbdbdb;
    border-bottom: .25rem solid rgba(0, 0, 0, 0);
  }
}

.c-phase__badge {
  color: #fafafa;
  text-transform: uppercase;
  background: #1d70b8;
  padding: max(.25rem, min(.23rem + .11vw, .31rem)) max(.75rem, min(.68rem + .33vw, .94rem));
}

.c-phase__text {
  align-items: center;
}

.c-radio-button {
  height: 44px;
  width: 44px;
}

.c-radio-button__outer-circle {
  fill: #fafafa;
  stroke: #050505;
  stroke-width: 5px;
}

.c-radio-button__inner-circle {
  fill: #008938;
}

.c-radio-button__inner-circle--unchecked {
  fill: #fafafa;
}

.c-reporting-status {
  border-bottom: .125rem solid #dbdbdb;
  padding-bottom: max(1rem, min(.91rem + .43vw, 1.25rem));
}

.c-search {
  color: #fafafa;
  z-index: 999;
  background: #242424;
  position: relative;
}

@media screen and (min-width: 850px) {
  .c-search {
    color: #050505;
    background: #fafafa;
  }
}

.c-search__toggle {
  cursor: pointer;
  min-height: 44px;
  min-width: 44px;
  background: none;
  background: inherit;
  color: inherit;
  height: 100%;
  width: 100%;
  border: 0;
  border-bottom: .375rem solid #242424;
  justify-content: center;
  align-items: center;
  padding: max(1rem, min(.91rem + .43vw, 1.25rem));
  font-family: inherit;
  display: inline-flex;
}

@media screen and (-ms-high-contrast: active) {
  .c-search__toggle {
    border: .25rem solid;
  }
}

.c-search__toggle--clicked {
  background: #424242;
  border-bottom: .375rem solid #424242;
}

@media screen and (min-width: 850px) {
  .c-search__toggle {
    width: 100%;
    align-self: flex-end;
    justify-self: flex-end;
    padding: 0;
    display: none;
  }
}

.c-search__form {
  color: inherit;
  width: 200%;
  background: #424242;
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
}

.c-search__form--closed {
  display: none;
}

@media screen and (min-width: 850px) {
  .c-search__form {
    color: #050505;
    max-width: 100%;
    position: unset;
    background: #fafafa;
    flex-direction: row;
    display: flex;
  }
}

.c-search__form--right {
  right: 0;
}

.c-search__input {
  border-radius: 0;
  flex-grow: 1;
  padding-left: max(.5rem, min(.46rem + .22vw, .63rem));
}

.c-search__icon {
  cursor: pointer;
  -webkit-filter: invert();
  filter: invert();
}

.c-status {
  white-space: nowrap;
  width: 2.5rem;
  word-break: initial;
  border: .125rem solid #050505;
  border-radius: .375rem;
  padding: max(.25rem, min(.23rem + .11vw, .31rem)) max(.5rem, min(.46rem + .22vw, .63rem));
}

.c-status--small {
  font-size: max(.89rem, min(.81rem + .39vw, 1.11rem));
}

.c-status--total {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-color: #9e9e9e;
}

.c-status--final {
  color: #fafafa;
  background: #050505;
}

.c-status--interim {
  color: #fafafa;
  background: #616161;
  border-color: #616161;
}

.c-status--in-development {
  color: #050505;
  background: #fafafa;
}

.c-status--bar-component {
  border-radius: unset;
  border: 0;
}

.c-status__bar-chart {
  height: spacing-units("small");
  border: .125rem solid #050505;
}

.c-theme-indicators {
  margin: 0;
  padding: 0;
  list-style: none;
}

.c-theme-indicators__item {
  border-bottom: .125rem solid #dbdbdb;
  padding-bottom: max(.75rem, min(.68rem + .33vw, .94rem));
}

.c-theme-indicators__status {
  word-break: initial;
  border: .125rem solid #050505;
  border-radius: .375rem;
  padding: max(.25rem, min(.23rem + .11vw, .31rem)) max(.5rem, min(.46rem + .22vw, .63rem));
  font-size: max(.89rem, min(.81rem + .39vw, 1.11rem));
}

.c-theme-indicators__status--final {
  color: #fafafa;
  background: #050505;
}

.c-theme-indicators__status--interim {
  color: #fafafa;
  background: #616161;
  border-color: #616161;
}

.c-theme-indicators__status--in-development {
  color: #050505;
  background: #fafafa;
}

.c-theme-indicators__status--bar-chart {
  border-radius: unset;
  border: 0;
}

.c-theme-tiles {
  border-top: .125rem solid #dbdbdb;
  padding-top: max(.5rem, min(.46rem + .22vw, .63rem));
}

.c-theme-tiles--stand-alone {
  border-top: 0;
}

.c-theme-tiles__tile {
  height: 100px;
  width: 100px;
  box-shadow: max(.5rem, min(.46rem + .22vw, .63rem)) max(.5rem, min(.46rem + .22vw, .63rem)) max(.5rem, min(.46rem + .22vw, .63rem)) rgba(5, 5, 5, .3);
}

.c-theme-tiles__tile--stand-alone {
  height: 200px;
  width: 200px;
}

.c-theme-tiles__tile--indicator {
  height: 165px;
  width: 165px;
}

.c-theme-tiles__image--stand-alone {
  height: 200px;
  width: 200px;
}

.c-theme-tiles__image--indicator {
  height: 165px;
  width: 165px;
}

.c-visualisation-container {
  min-height: 500px;
  height: auto;
  margin-top: auto;
  margin-bottom: auto;
}

.pagefind-ui {
  --pagefind-ui-scale: 1;
  --pagefind-ui-primary: #034ad8;
  --pagefind-ui-fade: #707070;
  --pagefind-ui-text: inspect(body. color);
  --pagefind-ui-background: inspect(body. background);
  --pagefind-ui-border: #eee;
  --pagefind-ui-tag: #eee;
  --pagefind-ui-border-width: 2px;
  --pagefind-ui-border-radius: 0px;
  --pagefind-ui-font: inspect(body. font-family);
}

table {
  border-collapse: collapse;
  border-top-color: gray;
  display: table-cell;
}

th {
  text-align: left;
  border-bottom: 1px solid #bfc1c3;
  padding: 1rem 2rem .5rem 0;
}

td {
  vertical-align: top;
  border-bottom: 1px solid #bfc1c3;
  padding: 1rem 2rem .5rem 0;
  display: table-cell;
}

.experimental-indicator {
  color: #fff;
  background-color: #0073e6;
  border-radius: 3px;
  margin-top: 10px;
  padding: 20px;
}

.experimental-indicator > p > a {
  color: #fff;
}

.experimental-indicator > p > a:hover {
  font-weight: bold;
}

address {
  font-style: normal;
}

.indicator-banner {
  position: relative;
}

.indicator-banner > div {
  padding: 2.2rem;
  margin: 0 !important;
}

.indicator-banner .indicator-icon {
  max-width: 100%;
  display: inline-block;
  padding-left: 0 !important;
}

.indicator-banner .indicator-details {
  text-align: center;
  display: flex;
  position: absolute;
  top: 24%;
  left: 16.5%;
}

@media only screen and (max-width: 768px) {
  .indicator-banner > div {
    padding: .3rem;
    margin: 0 !important;
  }

  .indicator-banner .indicator-details {
    text-align: left;
    margin-top: 10px;
    position: static;
    -webkit-transform: none;
    transform: none;
  }
}

/*# sourceMappingURL=main.css.map */
