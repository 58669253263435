// For markup see src/_includes/header.njk

.l-header {
  @include grid;

  gap: spacing-unit("x-small") spacing-unit("none");
  grid-template-areas:
    "phase phase"
    "feedback feedback"
    "logo logo"
    "nav search";
  grid-template-columns: repeat(2, 1fr);

  @media screen and (min-width: $tablet) {
    gap: spacing-unit("x-small") spacing-unit(small);
    grid-template-areas:
      "phase feedback"
      "logo search"
      "nav nav";
    margin-top: spacing-unit("xx-small");
  }
}

@each $area in "phase", "feedback", "logo", "nav", "search" {
  @if $area != "feedback" {
    .l-header__#{$area} {
      grid-area: unquote($string: $area);
    }
  } @else {
    .l-header__#{$area} {
      grid-area: unquote($string: $area);

      @media screen and (min-width: $tablet) {
        place-self: flex-end;
        width: fit-content;
      }
    }
  }
}
