// For markup see src/_includes/navigation.njk and

.c-navigation {
  @include add-menubar-styling;
  z-index: 998;
}

.c-navigation__toggle {
  @include add-menubar-toggle-styling;
}

.c-navigation__items {
  @include add-menubar-items-styling;
}

.c-navigation__item {
  &:focus,
  &:active {
    background: $yellow;
    color: shade("black");
  }

  @media screen and (min-width: $tablet) {
    border-bottom: line-width("small") solid transparent;

    &:hover {
      border-bottom: line-width("small") solid shade("black");
    }
  }
}

.c-navigation__link {
  @include remove-default-link-style;
  @include remove-default-button-style;
  @include add-custom-button-style;

  background: inherit;
  color: inherit;
  justify-content: flex-start;
  width: 100%;
}

.c-navigation__item .c-navigation__toggle {
  justify-content: space-between;

  &::after {
    content: "\25BC";
    transition: all $transition-time ease-in;
  }

  &--clicked::after {
    transform: rotate(90deg);
  }

  &:focus,
  &:active {
    border-bottom: line-width("none");
    box-shadow: none;
  }
}

.c-navigation__item .c-navigation__items {
  background: shade("dark-grey");
  color: shade("white");
  position: unset;
  width: 100%;

  @media screen and (min-width: $tablet) {
    background: shade("white");
    border: line-width("x-small") solid shade("light-grey");
    box-shadow: spacing-unit("none") spacing-unit("x-small")
      spacing-unit("x-small") $shadow;
    color: shade("black");
    flex-direction: column;
    margin-top: line-width("small");
    position: absolute;
    width: fit-content;

    &--closed {
      display: none;
    }
  }
}

.c-navigation__item .c-navigation__item {
  padding-left: spacing-unit(small);

  @media screen and (min-width: $tablet) {
    &:hover {
      background: shade("lightest-grey");
      border-bottom: line-width("small") solid transparent;
    }
  }
}
