.c-button {
  @include remove-default-link-style;
  @include remove-default-button-style;
  @include add-custom-button-style;

  background: $darker-defra-green;
  border-bottom: line-width("small") solid $darkest-defra-green;
  color: shade("white");
  padding: spacing-unit("x-small") spacing-unit("x-small");

  &:active,
  &:focus {
    background: $yellow;
    border-bottom: line-width("large") solid shade("black");
    box-shadow: 0 calc(line-width("small") * -1) $yellow;
    color: shade("black");
  }

  &--feedback {
    background: $dark-pink;
    border-bottom: line-width("small") solid $purple;
  }

  &--search {
    border-bottom: line-width("none");
    padding: spacing-unit("none");
  }

  &--search:focus,
  &--search:active {
    border: line-width("none");
  }

  &--fit-content {
    width: fit-content;
  }

  &--previous::before {
    // Heavy Left-Pointing Angle Quotation Mark Ornament
    content: "\276E";
    margin-right: spacing-unit("xx-small");
  }

  &--next::after {
    // Heavy Right-Pointing Angle Quotation Mark Ornament
    content: "\276F";
    margin-left: spacing-unit("xx-small");
  }
}


.closebtn {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;

  /* When moving the mouse over the close button */
  &:hover {
    color: black;
  }
}