// For markup see src/_includes/search.njk

.c-search {
  @include add-menubar-styling;
  z-index: 999;
}

.c-search__toggle {
  @include add-menubar-toggle-styling;

  padding: spacing-unit(small);

  @media screen and (min-width: $tablet) {
    padding: spacing-unit("none");
    place-self: flex-end;
    width: 100%;
  }
}

.c-search__form {
  @include add-menubar-items-styling;

  &--right {
    right: 0;
  }
}

.c-search__input {
  border-radius: 0;
  flex-grow: 1;
  padding-left: spacing-unit("xx-small");
}

.c-search__icon {
  cursor: pointer;
  filter: invert(1);
}
