// For markup see src/_includes/phase.njk

.c-phase__badge {
  background: $blue;
  color: shade("white");
  padding: spacing-unit("xxx-small") spacing-unit("x-small");
  text-transform: uppercase;
}

.c-phase__text {
  align-items: center;
}
