.c-indicator-component-tab {
  @include remove-default-button-style;
  @include touch-target;

  padding: spacing-unit("none");
}

.c-indicator-component-tab__text {
  pointer-events: none;
  text-align: left;
}

.c-indicator-component-tab__radio-button {
  @include touch-target;
}
