.experimental-indicator {
    background-color: #0073e6;
    border-radius: 3px;
    padding: 20px;
    color: white;
    margin-top: 10px;
  
    >p {
      >a {
        color: white;
  
        &:hover {
          font-weight: bold;
        }
      }
    }
  }
