.c-radio-button {
  height: 44px;
  width: 44px;
}

.c-radio-button__outer-circle {
  fill: shade("white");
  stroke: shade("black");
  stroke-width: 5;
}

.c-radio-button__inner-circle {
  fill: $defra-green;

  &--unchecked {
    fill: shade("white");
  }
}
