// For markup see src/_includes/breadcrumbs.njk

.c-breadcrumbs__items {
  @include remove-default-list-style;
}

.c-breadcrumbs__item:not(:first-child)::before {
  content: ">";
  margin-right: spacing-unit("xx-small");
}

.c-breadcrumbs__link {
  color: shade("black");
}
