// For markup see src/_includes/footer.njk

.c-footer {
  background: shade("lightest-grey");
  border-top: line-width("xx-small") solid shade("light-grey");
  padding-top: spacing-unit("xx-small");
}

.c-footer__items {
  @include remove-default-list-style;
}

.c-footer__link {
  color: shade("black");
}
