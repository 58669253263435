// For markup see src/_includes/_macros/frontpage-card.njk

.c-frontpage-card {
  border-top: line-width("xx-small") solid shade("lightest-grey");
  padding-top: spacing-unit("xx-small");
}

.c-frontpage-card__title {
  margin-bottom: spacing-unit("none");
}

.c-frontpage-card__content {
  flex-grow: 1;

  &--items {
    @include remove-default-list-style;
  }
}

.c-frontpage-card__link {
  color: $blue;
}
