// Removes the default (user-agent) styling, if added to list elements
//
// See https://moderncss.dev/css-only-accessible-dropdown-navigation-menu/#base-navigation-html
// for more details
@mixin remove-default-list-style {
  list-style: none;
  margin: line-width("none");
  padding: spacing-unit("none");
}

// Removes the default (user-agent) styling, if added to anchor elements
//
// See https://moderncss.dev/css-button-styling-guide/#reset-default-styles for details
@mixin remove-default-link-style {
  text-decoration: none;
}

// Removes the default (user-agent) styling, if added to button elements
//
// See https://moderncss.dev/css-button-styling-guide/#reset-default-styles for details
@mixin remove-default-button-style {
  background: transparent;
  border: spacing-unit("none");
  cursor: pointer;
  font-family: inherit;

  @media screen and (-ms-high-contrast: active) {
    border: line-width("small") solid currentcolor;
  }
}

// Ensures that elements that can be touch are at least 44px by 44px
//
// See https://www.w3.org/WAI/WCAG21/Understanding/target-size.html for more details
@mixin touch-target {
  min-height: 44px;
  min-width: 44px;
}

// Adds custom button display and visual styling, if added to button or link-as-button elements
// and ensures that elements that can be touch are at least 44px by 44px.
//
// See https://moderncss.dev/css-button-styling-guide/#display-styles and
// https://moderncss.dev/css-button-styling-guide/#visual-styles for more details
@mixin add-custom-button-style {
  @include touch-target;

  align-items: center;
  background: inherit;
  color: inherit;
  display: inline-flex;
  justify-content: center;
  padding: spacing-unit("xxx-small") spacing-unit("x-small");
}

// Turns an element into a flex column
//
// See https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Flexible_Box_Layout/Basic_Concepts_of_Flexbox
// for more details
@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin yellow-highlight {
  background: $yellow;
  box-shadow: 0 -2px $yellow, 0 4px shade("black");
  color: shade("black");
}

// Adds spacing unit modifiers to a block or element
// For example, if it's added to "l-column" it will generate
// "l-column--no-space" through "l-column--xxx-large-space"
@mixin add-spacing-unit-modifiers {
  @each $key, $value in $spacing-units {
    @if $key == "none" {
      &--no-space {
        gap: $value;
      }
    } @else {
      &--#{$key}-space {
        gap: $value;
      }
    }
  }
}

// Adds centring modifiers to a block or element
// For example, if it's added to "l-column" it will generate
// "l-column--centred" through "l-column--horizontally-centred"
@mixin add-centring-modifiers {
  &--centred {
    justify-content: center;
    place-items: center;
  }

  &--vertically-centred {
    align-items: center;
  }

  &--horizontally-centred {
    justify-content: center;
  }
}

// Turns an element into a two-dimensional "grid" container and with spacing and
// centring modifiers
//
// See https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Grid_Layout/Basic_Concepts_of_Grid_Layout
// for more details
@mixin grid {
  @include add-spacing-unit-modifiers;
  @include add-centring-modifiers;

  display: grid;
}

// Turns an element into a one-dimensional "flexbox" container and with spacing,
// centring, and flex specific modifiers
//
// See https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Flexible_Box_Layout/Basic_Concepts_of_Flexbox
// for more details
@mixin flex {
  @include add-spacing-unit-modifiers;
  @include add-centring-modifiers;

  display: flex;

  &--wrap {
    flex-wrap: wrap;
  }

  &--space-between {
    justify-content: space-between;
  }
}

@mixin add-menubar-styling {
  background: shade("darkest-grey");
  color: shade("white");
  position: relative;

  @media screen and (min-width: $tablet) {
    background: shade("white");
    color: shade("black");
  }
}

@mixin add-menubar-toggle-styling {
  @include remove-default-button-style;
  @include add-custom-button-style;

  border-bottom: line-width("large") solid shade("darkest-grey");
  height: 100%;
  width: 100%;

  &--clicked {
    background: shade("darker-grey");
    border-bottom: line-width("large") solid shade("darker-grey");
  }

  @media screen and (min-width: $tablet) {
    display: none;
  }
}

@mixin add-menubar-items-styling {
  @include remove-default-list-style;

  background: shade("darker-grey");
  color: inherit;
  position: absolute;
  width: 200%;

  &--closed {
    display: none;
  }

  @media screen and (min-width: $tablet) {
    background: shade("white");
    color: shade("black");
    display: flex;
    flex-direction: row;
    max-width: 100%;
    position: unset;
  }
}
