.c-status {
  border: line-width("xx-small") solid shade("black");
  border-radius: line-width("large");
  padding: spacing-unit("xxx-small") spacing-unit("xx-small");
  white-space: nowrap;
  width: 2.5rem;
  word-break: initial;

  &--small {
    font-size: font-size("small");
  }

  &--total {
    border-color: shade("light-grey");
    width: fit-content;
  }

  &--final {
    background: shade("black");
    color: shade("white");
  }

  &--interim {
    background: shade("dark-grey");
    border-color: shade("dark-grey");
    color: shade("white");
  }

  &--in-development {
    background: shade("white");
    color: shade("black");
  }

  &--bar-component {
    border: line-width("none");
    border-radius: unset;
  }

  &__bar-chart {
    border: shade("black") solid line-width("xx-small");
    height: spacing-units("small");
  }
}
