// For markup see src/_includes/theme-tiles.njk

.l-theme-tiles {
  @include grid;

  gap: spacing-unit("small");
  grid-template-columns: repeat(auto-fill, 100px);
  justify-content: center;
  margin-block: spacing-unit("small");

  &--stand-alone {
    grid-template-columns: repeat(auto-fill, 200px);
  }
}
