.l-column {
  @include flex;

  flex-direction: column;

  &--main {
    margin: spacing-unit("none") auto;
    width: min(95vw, 1170px);
  }

  &--vertical-bottom {
    justify-content: end;
  }
}
