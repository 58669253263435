table {
    border-collapse: collapse;
    border-top-color: gray;
    display: table-cell;
}

th {
    padding: 1rem 2rem 0.5rem 0rem;
    border-bottom: 1px solid #bfc1c3; 
    text-align: left;
}

td {
    vertical-align: top;
    padding: 1rem 2rem 0.5rem 0rem;
    border-bottom: 1px solid #bfc1c3;
    display: table-cell;
}