// For markup see src/_includes/frontpage-cards.njk

.l-cards {
  @include grid;

  gap: spacing-unit("small");

  @media screen and (min-width: $tablet) {
    grid-template-columns: repeat(3, 1fr);
  }
}
