// https://pagefind.app/docs/ui/#customising-the-styles

.pagefind-ui {
    --pagefind-ui-scale: 1;
    --pagefind-ui-primary: #034AD8;
    --pagefind-ui-fade: #707070;
    --pagefind-ui-text: inspect(body.color);
    --pagefind-ui-background: inspect(body.background);
    --pagefind-ui-border: #eeeeee;
    --pagefind-ui-tag: #eeeeee;
    --pagefind-ui-border-width: 2px;
    --pagefind-ui-border-radius: 0px;
    --pagefind-ui-font: inspect(body.font-family);
}

