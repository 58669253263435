body {
  // Ensure base applied as default for all other elements
  background: shade("white");
  color: shade("black");
  font-family: Arial, Helvetica, sans-serif;
  font-size: font-size("medium");
  line-height: line-height("medium");
}

p,
li,
h1,
h2,
h3,
h4 {
  // Clear top margin
  margin-top: 0;
  // Help prevent overflow of long words/names/URLs
  word-break: break-word;
}

h1,
h2,
h3,
h4 {
  // Set with `em` to be relative to current `font-size`
  font-weight: 500;
}

h1 {
  font-size: font-size("xxx-large");
  line-height: line-height("xxx-small");
}

h2 {
  font-size: font-size("xx-large");
  line-height: line-height("xx-small");
}

h3 {
  font-size: font-size("x-large");
  line-height: line-height("x-small");
}

h4 {
  font-size: font-size("large");
  line-height: line-height("small");
}
