.indicator-banner {
    position: relative;
  
    >div {
        padding: 2.2rem;
        margin: 0 !important;
    }

    .indicator-icon {
        display: inline-block;
        padding-left: 0 !important;
        max-width: 100%;
    }
  
    .indicator-details {
        display: flex;
        position: absolute;
        text-align: center;
        top: 24%;
        left: 16.5%;
    }

    @media only screen and (max-width: 768px) {
        >div {
            padding: 0.3rem;
            margin: 0 !important;
        }

        .indicator-details {
            text-align: left;
            position: static;
            margin-top: 10px;
            transform: none;
        }
    }
}