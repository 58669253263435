.c-indicator-component-visualisation-tab {
  @include remove-default-button-style;
  @include add-custom-button-style;

  background: shade("white");
  border: line-width("xxx-small") solid shade("light-grey");
  border-bottom: line-width("none");
  border-top: line-width("none");
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  box-shadow: 0 -6px 0 0 $defra-green;

  &--closed {
    background: shade("lightest-grey");
    border: line-width("xxx-small") solid shade("light-grey");
    border-bottom: line-width("xx-small") solid shade("light-grey");
    box-shadow: none;
  }

  &:focus,
  &:hover {
    background: shade("white");
  }
}

.c-indicator-component-visualisation-tab__text {
  border: line-width("xx-small") solid transparent;
  border-radius: 0.25rem;
  padding: spacing-unit("xxx-small") spacing-unit("xxx-small");
  pointer-events: none;

  &:focus,
  &:hover {
    background: shade("white");
    border: line-width("xx-small") solid $defra-green;
  }
}
