// For markup see src/_includes/theme-indicators.njk

.c-theme-indicators {
  @include remove-default-list-style;
}

.c-theme-indicators__item {
  border-bottom: line-width("xx-small") solid shade("lightest-grey");
  padding-bottom: spacing-unit("x-small");
}

.c-theme-indicators__status {
  border: line-width("xx-small") solid shade("black");
  border-radius: line-width("large");
  font-size: font-size("small");
  padding: spacing-unit("xxx-small") spacing-unit("xx-small");
  word-break: initial;

  &--final {
    background: shade("black");
    color: shade("white");
  }

  &--interim {
    background: shade("dark-grey");
    border-color: shade("dark-grey");
    color: shade("white");
  }

  &--in-development {
    background: shade("white");
    color: shade("black");
  }

  &--bar-chart {
    border: line-width("none");
    border-radius: unset;
  }
}
